import {MODALS_OPEN} from "../action";

const initialState = {
    openModal: {
        'login': false
    }
};

/**
 * MODALS REDUCER
 * @param {*} state
 * @param {*} action
 */
export function modalsReducer(state = initialState, action) {
    switch (action.type) {
        case MODALS_OPEN:
            return {...state, openModal: action.value};
        default:
            return state
    }
}
