import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

// Hooks
import { updatePage } from "../Store/action";
import useApi from "../Hooks/useApi";

// Components
import Metatags from "../Layout/metas-tags/meta-tags";
import HeaderHome from "../Components/header-page/header-home";
import F5cases from "../Flexibles/5cases";
import FCommuniques from "../Flexibles/communiques";
import FActus2cols from "../Flexibles/actus2cols";
import LoadPage from "../Components/load-page";

const TemplateHome = () => {
  const [dataFetch, isLoaded] = useApi({ name: "home" });
  const { metas, content } = dataFetch;

  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(updatePage("home"));
  }, []); // eslint-disable-line

  const renderPage = () => (
    isLoaded && (
      <>
        {metas && <Metatags {...metas} />}

        <HeaderHome {...content.banner} />
        <FActus2cols {...content.news} />

        {
          content.blocks_footer && <F5cases {...content.blocks_footer} />
        }

        { (!content.masquer_communiques) && <FCommuniques {...content.presses} /> }
      </>
    )
  );

  return isLoaded ? renderPage() : <LoadPage />
};

export default TemplateHome;
