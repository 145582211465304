import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import CLinkHover from '../Components/link-hover';
import ViewManager from '../Components/view-manager';

const FGrilleActusSmall = ({ title, news = [], limit = 4, nbMore = 4, listeactus = false, load_more, load_more_action, marginImage }) => {

    const [ref, visible] = ViewManager(0.2);
    const [nbItems, updateNbItems] = useState(limit);

    const moreItems = () => {
        updateNbItems(nbItems + nbMore);
    }

    const Article = (info) => {
        return (
            <>
                <div className="f-grilleactussmall_article_subarticle">
                    <p className="f-grilleactussmall_article_date">{info.date}</p>
                    <p className="f-grilleactussmall_article_titre">{info.title}</p>
                </div>
                <p className="f-grilleactussmall_article_visuel">
                    <span className="f-grilleactussmall_article_visuelContainer">
                        {
                            info.thumbnail && <img src={info.thumbnail} alt="" />
                        }
                    </span>
                </p>
            </>
        )
    }

    return (
        <div className={`flexible flexible--${marginImage} f-grilleactussmall ${listeactus ? "f-grilleactussmall--listeactus" : ""} container ${visible ? "blocInView" : ""}`} ref={ref}>
            <div className="subcontainer">
                {
                    title && <p className="f-grilleactussmall_titre"><span>{title}</span></p>
                }
                <div className="f-grilleactussmall_content">
                    {
                        news.filter((item, index) => index < nbItems).map((item, index) => <CLinkHover withZoom={true} type="plus" to={item.url} key={index} className="f-grilleactussmall_article">{Article(item)}</CLinkHover>)
                    }
                </div>
                {
                    load_more && <p className="f-grilleactussmall_bouton"><Link className="c-button c-button--type1" to={load_more.url}><span>{load_more.text}</span></Link></p>
                }
                {
                    !load_more && load_more_action && nbItems < news.length && <p className="f-grilleactussmall_bouton"><button onClick={e => moreItems()} className="c-button c-button--type1"><span>{load_more_action}</span></button></p>
                }
            </div>
        </div>
    )
};

export default FGrilleActusSmall;