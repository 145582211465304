import React, { useState }/*, {useState, useEffect}*/ from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import CLink from '../../Components/link';
import { updateGlobalStoreLang } from '../../Store/action';

const Header = () => {
    const { header, socials } = useSelector(state => state.global).global;
    const { menu, language } = header;
    const { linkedin, instagram } = socials;

    const [menuOpen, setMenuOpen] = useState(false);
    const [currentSubmenuOpen, setSubmenuOpen] = useState(-1);

    const toggleMenu = () => {
        if (menuOpen) {
            setSubmenuOpen(-1);
        }
        setMenuOpen(!menuOpen);
    }

    const closeMenu = () => {
        if (menuOpen) {
            setSubmenuOpen(-1);
        }
        setMenuOpen(false);
    }

    const checkClick = e => {
        if (e.target.classList.contains("menu")) {
            closeMenu();
        }
    }

    const toggleSubmenu = (index) => {
        setSubmenuOpen(currentSubmenuOpen === index ? -1 : index);
    }

    return (
        <>
            <div className={`menu ${menuOpen ? 'menu--open' : ''} ${currentSubmenuOpen !== -1 ? 'menu--subopen' : ''}`} onClick={e => checkClick(e)}>
                <button className="menu_burger" onClick={() => toggleMenu()}>
                    <span className="menu_burger_line menu_burger_line--top"></span>
                    <span className="menu_burger_line menu_burger_line--middle"></span>
                    <span className="menu_burger_line menu_burger_line--bottom"></span>
                </button>

                <div className="menu_main">
                    <ul className="menu_main_liste">
                        {
                            menu.map((item, index) => {
                                return (
                                    <li key={index} className={`menu_main_item ${currentSubmenuOpen === index ? 'menu_main_item--selected' : ''}`}>
                                        {
                                            item.submenu.length > 0 ?
                                                (
                                                    <>
                                                        <button onClick={() => toggleSubmenu(index)}><span>{item.title}</span></button>
                                                        <div className={`menu_second_mobile ${currentSubmenuOpen === index ? "menu_second_mobile--subopen" : ""}`}>
                                                            <ul className="menu_second_mobile_liste">
                                                                {
                                                                    item.submenu.map((subitem, subindex) => {
                                                                        return (
                                                                            <li key={index + '_' + subindex} className="menu_second_mobile_item"><Link onClick={() => closeMenu()} to={subitem.link.url} target={subitem.link.target} title={subitem.link.title}><span>{subitem.title}</span></Link></li>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <Link onClick={() => closeMenu()} to={item.link.url} target={item.link.target} title={item.link.title}><span>{item.title}</span></Link>
                                                )
                                        }
                                    </li>
                                )
                            })
                        }
                    </ul>
                    {language.length > 1 && <LanguageSwitch datas={language} onClose={closeMenu} />}
                </div>

                {
                    menu.map((item, index) => {
                        return (
                            <div className={`menu_second ${currentSubmenuOpen === index ? "menu_second--subopen" : ""}`} key={index}>
                                {
                                    item.submenu.length > 0 && <ul className="menu_second_liste">
                                        {
                                            item.submenu.map((subitem, subindex) => {
                                                return (
                                                    <li key={index + '_' + subindex} className="menu_second_item"><Link onClick={() => closeMenu()} to={subitem.link.url} target={subitem.link.target} title={subitem.link.title}><span>{subitem.title}</span></Link></li>
                                                )
                                            })
                                        }
                                    </ul>
                                }
                            </div>
                        )
                    })
                }
                <div className="menu_socials">
                    {linkedin && linkedin.url !== "" && <a className="menu_socials_linkedin" href={linkedin.url} target={linkedin.target} title={linkedin.title}></a>}
                    {instagram && instagram.url !== "" && <a className="menu_socials_instagram"  href={instagram.url} target={instagram.target} title={instagram.title}></a>}
                </div>

            </div>
        </>
    )
};
export default Header;

const LanguageSwitch = ({ datas, onClose }) => {

    const langStore = useSelector(state => state.global).lang;
    const dispatch = useDispatch();

    return (
        <div className="menu_lang">
            {datas.map((item, i) => (
                <div key={i} className="menu_lang--wrapper">
                    {i !== 0 && <span className="menu_lang--sep"></span>}
                    <button key={`lang-${i}`}
                        data-lang={item.lang}
                        data-lang-active={item.lang === langStore}
                        className="menu_lang--btn"
                        type="button"
                        onClick={() => { dispatch(updateGlobalStoreLang(item.lang)); onClose() }}>
                        {item.text}
                    </button>
                </div>
            ))}
        </div>
    );
};