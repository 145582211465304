import React from 'react';
import {useSelector} from "react-redux";
import CLink from '../../Components/link';
import ViewManager from '../../Components/view-manager';
import {Link} from "react-router-dom";

const Footer = () => {
    const {footer, socials} = useSelector(state => state.global).global;
    const {address, email_address, copyrights, menu, linkedin_link} = footer;
    const {linkedin, instagram} = socials;

    const [ref, visible] = ViewManager();

    return (
        <footer className={`footer ${visible ? "blocInView" : ""}`} ref={ref}>
            <div className="footer_address">
                <p className="footer_address_logo"><img src="/assets/img/logo.svg" alt=""/></p>
                <p>{address.line_1}<br/>{address.line_2}</p>
                <p><a target="_blank" rel="noopener noreferrer" href={`mailto:${email_address}`}>{email_address}</a></p>
            </div>
            <div className="footer_liens">
                <ul>
                    {
                        menu.filter(item => item.link.url).map((item, index) => <li key={index}>
                            <Link to={item.link.url}>{item.title}</Link></li>)
                    }
                    <li><span>{copyrights}</span></li>
                </ul>
            </div>

            <div className="footer_socials">
                {
                    linkedin && linkedin.url !== "" && <CLink {...linkedin}>
                        <img src="/assets/img/picto_linkedin.svg" alt=""/>
                    </CLink>
                }
                {
                    instagram && instagram.url !== "" && <CLink {...instagram}>
                        <img src="/assets/img/picto_instagram.svg" alt=""/>
                    </CLink>
                }
            </div>

            <div className="footer_scrolltotop">
                <button onClick={() => window.scrollTo(0, 0)}><img src="/assets/img/scrolltotop.svg" alt=""/></button>
            </div>
        </footer>
    )
};

export default Footer;