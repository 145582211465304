import React, { useRef, useState } from "react";
import ViewManager from "../Components/view-manager";
import YouTube from 'react-youtube';

const FVideo = ({ video, marginImage }) => {

    const [ref, visible] = ViewManager();

    const [isStarted, setStarted] = useState(false);
    const [youtubePlayer, setYoutubePlayer] = useState(null);
    const youtube = useRef(null);

    const opts = {
        playerVars: {
          playsinline: 1,
        },
      };

    const startVideo = () => {
        if (youtube.current && youtubePlayer) {
            setStarted(true);
            youtubePlayer.target.playVideo();
        }
    }

    const youtubeReady = (e) => {
        setYoutubePlayer(e);
    }

    const interfaceState = (e) => {
        if (e.data === 1) {
            //playing();
        } else if (e.data === 0 || e.data === 2) {
            //pausing();
        }
    }

    if(!window.tarteaucitron.state.youtubeapi){
        return null;
    }

    return (
        <div className={`flexible flexible--${marginImage} f-video ${visible ? "blocInView" : ""}`} ref={ref}>
            <div className="container">
                <p className="f-video_titre" dangerouslySetInnerHTML={{ __html: video.title }}></p>
                <div className="f-video_box">
                    <YouTube ref={youtube} videoId={video.id} onReady={e => youtubeReady(e)} opts={opts} onStateChange={e => interfaceState(e)} />
                    {
                        !isStarted &&
                        <div className="f-video_boxInterface">
                            <img src={"https://img.youtube.com/vi/" + video.id + "/maxresdefault.jpg"} alt="" />
                            <button onClick={() => startVideo()}></button>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default FVideo;
