import React from 'react';
import { Link } from 'react-router-dom';
import ViewManager from '../view-manager';

const HeaderType2 = ({ back_to, date, text_scroll, thumbnail, title, main_title }) => {
    const [ref, visible] = ViewManager();

    return (
        <>
            <header className="header">
                <Link to={"/"} className="header_logo"><img src="/assets/img/logo_big_noir.svg" alt="" /></Link>
            </header>
            <div ref={ref} className={`headerType2 ${visible ? "blocInView" : ""}`}>
                <div className="headerType2_box container">
                    <div className="headerType2_box_square1">
                        <div className="headerType2_infos">
                            <p className="headerType2_categorie">{main_title}</p>
                            <p className="headerType2_backlink"><Link to={back_to.url}>{back_to.text}</Link></p>
                        </div>
                        <div className="headerType2_box_relative headerType2_box_relative--notSquareMobile">
                            <div className="headerType2_box_absolute headerType2_box_square1_content">
                                <p className="headerType2_date">{date}</p>
                                <h1 className="headerType2_titre" dangerouslySetInnerHTML={{ __html: title }}></h1>
                                <button className="headerType2_scroll" onClick={() => window.scrollTo(0, window.innerHeight)}>{text_scroll}</button>
                            </div>
                        </div>
                    </div>
                    <div className="headerType2_box_square2">
                        <div className="headerType2_box_relative">
                            <div className="headerType2_box_absolute headerType2_box_square2_content">
                                <img className="headerType2_thumbnail" src={thumbnail} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default HeaderType2;
