import React from "react";
import ButtonArrow from "../Components/button-arrow";
import CLink from "../Components/link";
import ViewManager from "../Components/view-manager";

const FText2Cols = ({ title, text_two_columns, cta, className = "", marginImage }) => {

  const [ref, visible] = ViewManager();

  return (
    <div className={`flexible flexible--${marginImage} f-text2cols container container--notfull ${className} ${visible ? "blocInView" : ""}`} ref={ref}>
      <div className="subcontainer">
        {/*  second title is optionnal */}
        {title && <h2 className="f-text2cols_secondtitre">{title}</h2>}
        <div className="f-text2cols-container">
          {text_two_columns && (
            <div className="f-text2cols-container_text">
              <div>
                <div className="wysiwyg" dangerouslySetInnerHTML={{ __html: text_two_columns.text_left }}></div>
                {cta && <p className={`f-text2cols_button`}><CLink {...cta} /></p>}
              </div>
              <div className="wysiwyg" dangerouslySetInnerHTML={{ __html: text_two_columns.text_right }}></div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FText2Cols;
