import React from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ViewManager from "../Components/view-manager";
import ButtonArrow from "../Components/button-arrow";

const FSlider3options = ({ cta, slides, marginImage }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  const [ref, visible] = ViewManager();

  return (
    <div
      ref={ref}
      className={`flexible flexible--${marginImage} f-titreTexteImg f-titreTexteImg--slider container ${visible ? "blocInView" : ""
        }`}
    >
      <div className="subcontainer">
        {slides && (
          <div className="f-slider3options-slider">
            <Slider {...settings}>
              {slides.map((slide, index) => {
                return (
                  <div key={index} className="f-slider3options-container">
                    {slide.picture &&
                      slide.text === undefined &&
                      slide.title === undefined && (
                        <div className="f-slider3options-container-ContainerDouble">
                          <img
                            className="f-slider3options-container-imgAlone"
                            src={slide.picture.src}
                            alt={slide.picture.alt}
                          />
                        </div>
                      )}
                    {slide.picture &&
                      (slide.text !== undefined ||
                        slide.title !== undefined) && (
                        <>
                          <div className="f-slider3options-container-Container">
                            <img
                              className="f-slider3options-container-imgText"
                              src={slide.picture.src}
                              alt={slide.picture.alt}
                            />
                          </div>
                          <div className="f-slider3options-container-Container">
                            {slide.title && (
                              <div className="f-slider3options-container-content_black">
                                {slide.title && (
                                  <div>
                                    <h1 className="f-slider3options-container-content_black_titre">
                                      {slide.title}
                                    </h1>
                                    {slide.subtitle && (
                                      <h2 className="f-slider3options-container-content_black_soustitre">
                                        {slide.subtitle}
                                      </h2>
                                    )}
                                  </div>
                                )}

                                {slide.cta && (
                                  <p className={`f-titreTexteImg_button  f-slider3options-button`}>
                                    <ButtonArrow
                                      color={"white"}
                                      type={"plus"}
                                      className={`f-slider3options-container-content_black-cta`}
                                      cta={slide.cta}
                                    ></ButtonArrow>
                                  </p>
                                  // <div className="f-slider3options-container-content_black-cta">
                                  //   <a
                                  //     href={slide.cta.url}
                                  //     target={slide.cta.target}
                                  //   >
                                  //     <h2 className="f-slider3options-container-content_black-cta__button f-slider3options-container-content_black-cta__button--plus f-slider3options-container-content_black-cta__button--white">
                                  //       {slide.cta.title}
                                  //     </h2>
                                  //   </a>
                                  // </div>
                                )}
                              </div>
                            )}
                            {slide.text && (
                              <div className="f-slider3options-container-ContainerAbsolute">
                                <div className="f-slider3options-container-content_white">
                                  {slide.text && (
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: slide.text,
                                      }}
                                    ></div>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        </>
                      )}
                  </div>
                );
              })}
            </Slider>
          </div>
        )}
        {cta && (
          <p className={`f-titreTexteImg_button f-slider3options-cta`}>
            <ButtonArrow cta={cta}></ButtonArrow>
          </p>
        )}
      </div>
    </div>
  );
};

export default FSlider3options;
