import { useState, useEffect } from 'react';
import { useInView } from "react-intersection-observer";

const ViewManager = (threshold = 0.4) => {
    const [visible, setVisible] = useState(false);
    const [ref, inView] = useInView({ threshold, triggerOnce: true });

    useEffect(() => {
        setVisible(inView);
    }, [inView]);

    return [ref, visible];
}

export default ViewManager; 