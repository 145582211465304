import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import CLinkHover from '../Components/link-hover';
import ViewManager from '../Components/view-manager';

const FCommuniques = ({download, items, load_more, title, isLoadMore = false, titleHidden = false, className = "", currentItems = 3, marginImage}) => {

    const [ref, visible] = ViewManager();
    const [nbItems, updateNbItems] = useState(currentItems);

    const moreItems = () => {
        updateNbItems(nbItems + 6);
    }

    return (
        <div
            className={`flexible flexible--${marginImage} f-communiques container container--notfull ${visible ? "blocInView" : ""} ${className}`}
            ref={ref}>
            <div className="subcontainer">
                {
                    !titleHidden && title && <p className="f-communiques_maintitre">{title}</p>
                }
                <ul className="f-communiques_content">
                    {
                        items.filter((item, index) => index < nbItems).map((item, index) => {
                            return (
                                <li key={index} className="f-communiques_item">
                                    <CLinkHover to={item.file ? item.file : item.url}
                                                type={item.file ? "download" : "plus"} isNotLink={item.file}>
                                        <div className="f-communiques_item_container">
                                            { item.date && <p className="f-communiques_date">{item.date}</p> }
                                            <p className="f-communiques_titre"
                                               dangerouslySetInnerHTML={{__html: item.title}}/>
                                            {
                                                (item.file || item.url) && <p className="f-communiques_lien"><span className={`c-button c-button--${item.file ? "download" : "read"}`}>{ item.file && download ? download : item.link.title}</span></p>
                                            }
                                        </div>
                                    </CLinkHover>
                                </li>
                            )
                        })
                    }
                </ul>
                {
                    isLoadMore ?
                        (
                            (nbItems < items.length) && <p className="f-communiques_bouton">
                                <button onClick={() => moreItems()} className="c-button c-button--type1">
                                    <span>{load_more}</span></button>
                            </p>
                        ) : (
                            <p className="f-communiques_bouton"><Link className="c-button c-button--type1"
                                                                      to={load_more.url}><span>{load_more.text}</span></Link>
                            </p>
                        )
                }

            </div>
        </div>
    )
};

export default FCommuniques;