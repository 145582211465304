import React from "react";
import ButtonArrow from "../Components/button-arrow";
import ViewManager from "../Components/view-manager";

const FTitreTexteImg = ({ title, text, direction_picture, framed_picture, cta, picture, margin_mobile, ferrage_mobile, marginImage }) => {

  const [ref, visible] = ViewManager();

  return (
    <div ref={ref} className={`flexible flexible--${marginImage} f-titreTexteImg container container--notfull f-titreTexteImg--${margin_mobile} ${visible ? "blocInView" : ""}`}>
      <div className="subcontainer">
        {text && (
          <div
            className={`f-titreTexteImg-container f-titreTexteImg-container-${direction_picture}`}
          >
            <div className="f-titreTexteImg-container-textes">
              {/*  second title is optionnal */}
              {title && (
                <h2 className="f-titreTexteImg-container-textes_secondtitre">
                  {title}
                </h2>
              )}
              {text && (
                <div
                  className="f-titreTexteImg-container-textes_text"
                  dangerouslySetInnerHTML={{ __html: text }}
                ></div>
              )}
              {cta && <p className={`f-titreTexteImg_button`}><ButtonArrow cta={cta}></ButtonArrow></p>}
            </div>
            {picture && (
              <div className={`f-titreTexteImg-container-img f-titreTexteImg--${ferrage_mobile}`}>
                <div className={`f-titreTexteImg-container-imgAbsolute`}>
                  <img src={picture.src} alt={picture.alt} />
                  {framed_picture && (
                    <div className="f-titreTexteImg-container-img__border"></div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}

        {text === "" && picture && (
          <div
            className={`f-titreTexteImg-container f-titreTexteImg-container-imageSeule${direction_picture}`}
          >
            {picture && (
              <div className={`f-titreTexteImg-container-img f-titreTexteImg--${ferrage_mobile}`}>
                <div className={`f-titreTexteImg-container-imgAbsolute`}>
                  <img src={picture.src} alt={picture.alt} />
                  {framed_picture && (
                    <div className="f-titreTexteImg-container-img__border"></div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default FTitreTexteImg;
