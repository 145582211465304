import React, { useEffect } from "react";

// Hooks
import { useDispatch } from 'react-redux';
import { updatePage } from '../Store/action';
import { useLocation } from 'react-router-dom';
import useApi from '../Hooks/useApi';

// Components
import ComponentByJSON from "../Layout/flexibles/component-from-json";
import Metatags from '../Layout/metas-tags/meta-tags';
import HeaderType2 from "../Components/header-page/header-type2";
import LoadPage from "../Components/load-page";

const TemplateArticle = ({ _uid }) => {

    const location = useLocation();
    const [dataFetch, isLoaded] = useApi({ name: 'posts', slug: location.pathname, _uid });
    const { metas, content } = dataFetch;

    let dispatch = useDispatch();
    useEffect(() => {
        dispatch(updatePage('posts'));
    }, []); // eslint-disable-line

    const renderPage = () => (
        isLoaded && <>
            <Metatags {...metas} />

            <HeaderType2 {...content.banner} />

            <div className={`theme--${content.banner.theme}`}>
                {content.flexibles.map((block, index) => ComponentByJSON(block, content.flexibles[index + 1]))}
            </div>
        </>
    )

    return isLoaded ? renderPage() : <LoadPage />
};

export default TemplateArticle;