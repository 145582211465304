import React, { useEffect } from "react";

// Hooks
import { useDispatch } from 'react-redux';
import { updatePage } from '../Store/action';
import { useLocation } from 'react-router-dom';
import useApi from '../Hooks/useApi';

// Components
import Metatags from '../Layout/metas-tags/meta-tags';
import F5cases from "../Flexibles/5cases";
import FText2Cols from "../Flexibles/text2cols";
import FTitreText from "../Flexibles/titreTexte";
import HeaderType3 from "../Components/header-page/header-type3";
import LoadPage from "../Components/load-page";

const TemplateOffreDetail = ({ _uid }) => {

    const location = useLocation();
    const [dataFetch, isLoaded] = useApi({ name: 'posts', slug: location.pathname, _uid });
    const { metas, content } = dataFetch;

    const GetItem = (item) => {
        if (item.type === "full_width") {
            return <FTitreText {...item} className="detail_offre" />
        } else if (item.type === "split_width") {
            return <FText2Cols {...item} className="detail_offre" />
        } else {
            return null;
        }
    }

    let dispatch = useDispatch();
    useEffect(() => {
        dispatch(updatePage('posts'));
    }, []); // eslint-disable-line

    const renderPage = () => (
        isLoaded && <>
            <Metatags {...metas} />

            <HeaderType3 {...content.banner} />

            <div className={`theme--${content.banner.theme} ${content.blocks_footer && content.blocks_footer.choice !== "display_four" ? "withBlocFooter" : ""}`}>
                {
                    content.content.map((item, index) => <GetItem {...item} key={index} />)
                }

            </div>

            <div className="container container--notfull onlyMobile">
                <div className="subcontainer detail_offre_postulerMobile">
                    <a className="c-button c-button--btnArrow" href={"mailto:" + content.banner.apply.mailto} target="_blank">{content.banner.apply.mobile_text}</a>
                </div>
            </div>

            {
                content.blocks_footer && <F5cases {...content.blocks_footer} />
            }
        </>
    )

    return isLoaded ? renderPage() : <LoadPage />
};

export default TemplateOffreDetail;