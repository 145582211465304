import React from 'react';
import { Link } from 'react-router-dom';
import CLinkHover from '../Components/link-hover';
import ViewManager from '../Components/view-manager';

const FActus2cols = ({ items, load_more, marginImage }) => {

    const [ref, visible] = ViewManager();

    const Article = (info) => {
        return (
            <>
                <div className="f-actus2cols_article_subarticle">
                    <p className="f-actus2cols_article_date">{info.date}</p>
                    <p className="f-actus2cols_article_titre" dangerouslySetInnerHTML={{ __html: info.title }}></p>
                </div>
                <p className="f-actus2cols_article_visuel">
                    <span className="f-actus2cols_article_visuelContainer">
                        {
                            info.thumbnail && info.thumbnail.url && <img src={info.thumbnail.url} alt={info.thumbnail.alt ? info.thumbnail.alt : ""} />
                        }
                    </span>
                </p>
            </>
        )
    }

    return (
        <div className={`flexible flexible--${marginImage} f-actus2cols container ${visible ? "blocInView" : ""}`} ref={ref}>
            <div className="subcontainer">
                <div className="f-actus2cols_content">
                    <div className="f-actus2cols_col">
                        {
                            items[0] && <CLinkHover to={items[0].url} type="plus" className="f-actus2cols_article f-actus2cols_article--main" withZoom={true}>{Article(items[0])}</CLinkHover>
                        }
                    </div>
                    <div className="f-actus2cols_col">
                        {
                            items.filter((item, index) => index > 0).map((item, index) => <CLinkHover to={item.url} key={index} type="plus" className="f-actus2cols_article" withZoom={true}>{Article(item)}</CLinkHover>)
                        }
                    </div>
                </div>
                <p className="f-actus2cols_bouton"><Link className="c-button c-button--type1" to={load_more.url}><span>{load_more.text}</span></Link></p>
            </div>
        </div>
    )
};

export default FActus2cols;