import React from "react";

const Checkbox = ({ label, required, id, name, error = "" }) => {
    return (
        <div className={`c-checkbox ${error !== "" ? "c-checkbox--error" : ""}`}>
            <input type="Checkbox" id={id} name={name} required={required}></input>
            <label htmlFor={id}>{label}</label>
        </div>
    )
};

export default Checkbox;