import React from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ViewManager from "../Components/view-manager";

const FTitreTexteSlider = ({ text, title, slides, marginImage }) => {

  const settings = {
    dots: true,
    infinite: true,
    arrows: false
  };

  const [ref, visible] = ViewManager();
  
  return (
    <div ref={ref} className={`flexible flexible--${marginImage} f-titreTexteImg f-titreTexteImg--slider container container--notfull ${visible ? "blocInView" : ""}`}>
      <div className="subcontainer">
        <div className="f-titreTexteImg-container">
          <div className="f-titreTexteImg-container-textes">
            {/*  second title is optionnal */}
            <h2 className="f-titreTexteImg-container-textes_secondtitre">
              {title}
            </h2>
            <div
              className="f-titreTexteImg-container-textes_text"
              dangerouslySetInnerHTML={{ __html: text }}
            ></div>
          </div>
          <div className="f-titreTexteImg-container-slider">
            {slides && (
              <Slider {...settings}>
                {slides.map((slide, index) => {
                  return (
                    <div key={index}  className="f-titreTexteImg-container-slider_boxAbsolute f-titreTexteImg-container-slider_boxAbsolute--slider">
                      <img src={slide.src} alt={slide.alt} />
                    </div>
                  );
                })}
              </Slider>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FTitreTexteSlider;
