import React from "react";
import { Link } from "react-router-dom";

const CLink = ({ children, className, url, title, target, external = false, internal, handleClick, withZoom = false }) => {

    if(internal !== null){
        external = !internal;
    }

    return (
        <>
            {(!external) ?
                <Link to={url} title={title} target={target} className={`link ${withZoom ? "link--withImage" : ""} ${className}`}
                    onClick={handleClick}>{children ? children : title}</Link>
                :
                <a href={url} title={title} target={target} className={`link ${withZoom ? "link--withImage" : ""} ${className}`}
                    onClick={handleClick}>{children ? children : title}</a>
            }
        </>
    )
};

export default CLink;