import './Styles/App.scss';
import React, {useEffect, useState} from 'react';
import ScrollToTop from './Components/ScrollToTop';
import {BrowserRouter as Router, Switch} from 'react-router-dom';
import RouteForTemplates from './Layout/routes/route-for-templates';

// HOOKS
import useApi from './Hooks/useApi';
import {useSelector, useDispatch} from 'react-redux';
import {
    updateGlobalStoreGlobal,
    updateGlobalStoreRoutes,
    updateGlobalScrollLock,
    updateGlobalStoreLang,
    updateIsMobile,
    updateIsDesktop,
    updateGA
} from './Store/action';

// TPL PARTS
import Header from './Layout/header/header';
import Footer from './Layout/footer/footer';

// LIBRARIES
// GA
import ReactGA from "react-ga";

// ReactGA.initialize('UA-test-00000');

const App = () => {
    // Global apis
    let dispatch = useDispatch();
    let globalStore = useSelector(state => state.global);

    // API dynamiques ou via des JSON
    let [dataGlobalAPI, dataGlobalIsLoaded] = useApi({name: 'global'});
    let [dataRoutesAPI, routesIsLoaded] = useApi({name: 'routes'});

    let {ga, global} = globalStore;
    //let { routes, isLoaded } = globalStore;
    //let dataRoutes = routes;

    let page = useSelector(state => state.page).page;

    let {mobile, desktop} = useSelector(state => state.breakpoint);

    // INIT SCROLL LOCK REDUCER // DISPATCH LANGUAGE FROM URL
    useEffect(() => {
        dispatch(updateGlobalScrollLock(false));

        let langArray = window.location.href.split('/');
        langArray[3] === 'en' ? dispatch(updateGlobalStoreLang('en')) : dispatch(updateGlobalStoreLang('fr'));

    }, []); // eslint-disable-line


    useEffect(() => {
        let init = true;
        let init2 = true;
        if (window.tarteaucitron) {
            let timer = setInterval(() => { // eslint-disable-line

                if (window.tarteaucitron.state['gtag'] === true && ga === false && init === true) {
                    dispatch(updateGA(true));
                    init = false;
                } else if (window.tarteaucitron.state['gtag'] === false && ga === true && init2 === true) {
                    dispatch(updateGA(false));
                    init2 = false;
                }
            }, 1000);
        }
    }, []); // eslint-disable-line


    const [hasInit, setHasInit] = useState(false);
    useEffect(() => {
        if (ga === true && dataGlobalIsLoaded && hasInit === false) {
            ReactGA.initialize(global.ua);
            setHasInit(true);
        }
    }, [ga, dataGlobalIsLoaded]); // eslint-disable-line


    // STORE ADD GLOBAL API
    useEffect(() => {
        dispatch(updateGlobalStoreGlobal(dataGlobalAPI));
    }, [dataGlobalAPI]); // eslint-disable-line

    // STORE ADD ROUTES API
    useEffect(() => {
        dispatch(updateGlobalStoreRoutes(dataRoutesAPI));
    }, [dataRoutesAPI]); // eslint-disable-line

    useEffect(() => {
        if (dataGlobalIsLoaded && routesIsLoaded) {
            window.prerenderReady = true;
        }
    }, [dataGlobalIsLoaded, routesIsLoaded]);


    // DISPATCH WINDOW SIZE ON RESIZE
    useEffect(() => {
        const checkDevice = () => {
            if (window.matchMedia('(max-width:1023px)').matches && !mobile) {
                dispatch(updateIsMobile(true));
                dispatch(updateIsDesktop(false));
            }
            if (window.matchMedia('(min-width:1024px)').matches && !desktop) {
                dispatch(updateIsMobile(false));
                dispatch(updateIsDesktop(true))
            }
        };

        checkDevice();

        window.addEventListener('resize', checkDevice);

        // clean listener
        return () => {
            window.removeEventListener('resize', checkDevice);
        }
    }, [mobile, desktop]);// eslint-disable-line

    return (
        <div className={`app ${page}`}>
            <Router>
                <ScrollToTop>

                    {
                        dataGlobalIsLoaded && routesIsLoaded && <>
                            <Header/>
                            <Switch>
                                {dataRoutesAPI && (
                                    dataRoutesAPI.map((route) => {
                                        return <RouteForTemplates key={route._uid} {...route} datas={{}}/>;
                                    })
                                )}
                            </Switch>
                            <Footer/>
                        </>
                    }
                </ScrollToTop>
            </Router>

        </div>
    );
};

export default App;