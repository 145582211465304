import React, { useEffect } from "react";

// Hooks
import { useDispatch } from 'react-redux';
import { updatePage } from '../Store/action';
import { Link, useLocation } from 'react-router-dom';
import useApi from '../Hooks/useApi';

// Components
import Metatags from '../Layout/metas-tags/meta-tags';
import F5cases from "../Flexibles/5cases";
import FGrilleActusSmall from "../Flexibles/grilleactussmall";
import ViewManager from "../Components/view-manager";
import LoadPage from "../Components/load-page";
import CLinkHover from "../Components/link-hover";

const TemplateActualites = ({ _uid }) => {

    const [ref, visible] = ViewManager();

    const location = useLocation();
    const [dataFetch, isLoaded] = useApi({ name: 'pages', slug: location.pathname, _uid });
    const { metas, content } = dataFetch;

    let dispatch = useDispatch();
    useEffect(() => {
        dispatch(updatePage('pages'));
    }, []); // eslint-disable-line

    const renderPage = () => (
        isLoaded && <>
            <Metatags {...metas} />

            <header className="header">
                <Link to={"/"} className="header_logo"><img src="/assets/img/logo_big_noir.svg" alt="" /></Link>
            </header>

            <div className={`theme--${content.theme} ${content.blocks_footer && content.blocks_footer.choice !== "display_four" ? "withBlocFooter" : ""}`}>
                <div ref={ref} className={`headerActus container ${visible ? "blocInView" : ""}`}>
                    <div className="headerActus_blocnoir">
                        <div className="headerActus_blocnoir_content">
                            <h1 className="headerActus_blocnoir_titre">{content.title}</h1>
                            <button className="headerActus_blocnoir_scroll" onClick={() => window.scrollTo(0, window.innerHeight)}>{content.text_scroll}</button>
                        </div>
                    </div>
                    <CLinkHover withZoom={true} to={content.news.latest.url} type="plus" className="headerActus_article">
                        <p className="headerActus_article_img"><img src={content.news.latest.thumbnail} alt="" /></p>
                        <p className="headerActus_article_date">{content.news.latest.date}</p>
                        <p className="headerActus_article_title" dangerouslySetInnerHTML={{ __html: content.news.latest.title }}></p>
                    </CLinkHover>
                </div>

                <FGrilleActusSmall news={content.news.others} limit={8} nbMore={8} listeactus={true} load_more_action={content.load_more} />
            </div>

            {
                content.blocks_footer && <F5cases {...content.blocks_footer} />
            }
        </>
    )

    return isLoaded ? renderPage() : <LoadPage />
};

export default TemplateActualites;