import React, { useEffect, useRef } from 'react';
import ViewManager from '../Components/view-manager';
import { useSelector } from 'react-redux';

const FKeynumbers = ({ title, key_figures, marginImage }) => {

    let { mobile } = useSelector(state => state.breakpoint);
    const [ref, visible] = ViewManager(0.1);

    const numberClone = useRef(null);
    const number = useRef(null);

    const mouseCloneEnter = e => {
        if (!mobile) {
            numberClone.current.sizeCircle = 50;
            numberClone.current.nextSize = 50;

            numberClone.current.posX = 0;
            numberClone.current.posY = 0;
            numberClone.current.forceX = null;
            numberClone.current.forceY = null;
            numberClone.current.currentX = null;
            numberClone.current.currentY = null;

            numberClone.current.isDrawing = true;

            drawCircle(0);
        }
    }
    const mouseCloneLeave = e => {
        if (!mobile) {
            numberClone.current.sizeCircle = 0;
            numberClone.current.nextSize = 0;

            numberClone.current.posX = 0;
            numberClone.current.posY = 0;
            numberClone.current.forceX = null;
            numberClone.current.forceY = null;
            numberClone.current.currentX = null;
            numberClone.current.currentY = null;

            numberClone.current.isDrawing = false;
        }
    }
    const mouseItemEnter = e => {
        if (!mobile) {
            let el = e.target;
            numberClone.current.nextSize = 160;
            numberClone.current.forceX = el.offsetLeft + el.offsetParent.offsetLeft + el.clientWidth * 0.5;
            numberClone.current.forceY = el.offsetTop + el.offsetParent.offsetTop + el.clientHeight * 0.5;
        }
    }
    const mouseItemLeave = e => {
        if (!mobile) {
            numberClone.current.nextSize = 50;
            numberClone.current.forceX = null;
            numberClone.current.forceY = null;
        }
    }
    const mouseMove = e => {
        if (!mobile) {
            let infos = numberClone.current.getBoundingClientRect();
            numberClone.current.posX = e.clientX;
            numberClone.current.posY = -infos.top + e.clientY;
        }
    }

    const updateDiff = (next, current, amplitude) => {
        let diff = next - current;
        if (Math.abs(diff) > amplitude) {
            let sens = diff / Math.abs(diff);
            return current + amplitude * sens;
        } else {
            return next;
        }
    }

    const drawCircle = () => {
        if (numberClone && numberClone.current) {
            if (numberClone.current.isDrawing) {
                numberClone.current.sizeCircle = updateDiff(numberClone.current.nextSize, numberClone.current.sizeCircle, 10)

                if (mobile) {
                    let posX = (numberClone.current.forceX ? numberClone.current.forceX : numberClone.current.posX);
                    let posY = (numberClone.current.forceY ? numberClone.current.forceY : numberClone.current.posY);

                    if (!numberClone.current.currentX) {
                        numberClone.current.currentX = posX;
                    }

                    if (!numberClone.current.currentY) {
                        numberClone.current.currentY = posY;
                    }

                    numberClone.current.currentX = updateDiff(posX, numberClone.current.currentX, 40)
                    numberClone.current.currentY = updateDiff(posY, numberClone.current.currentY, 40)
                } else {
                    numberClone.current.currentX = numberClone.current.posX
                    numberClone.current.currentY = numberClone.current.posY
                }

                numberClone.current.style.clipPath = "circle(" + numberClone.current.sizeCircle + "px at " + numberClone.current.currentX + "px " + numberClone.current.currentY + "px)";

                requestAnimationFrame(() => drawCircle());
            } else {
                numberClone.current.style.clipPath = "circle(0px at 0px 0px)";
            }
        }
    }

    useEffect(() => {
        const checkCircle = () => {
            if (mobile && number.current && numberClone.current) {
                if (!number.current.items) {
                    number.current.items = number.current.querySelectorAll(".f-keynumbers_item");
                }

                let windowHeight = window.innerHeight * 0.5;
                let anyConcerned = false;

                number.current.items.forEach((item, index) => {
                    let info = item.getBoundingClientRect();
                    if (info.top < windowHeight && info.bottom > windowHeight) {
                        numberClone.current.forceX = item.offsetLeft + item.offsetParent.offsetLeft + item.clientWidth * 0.5;
                        numberClone.current.forceY = item.offsetTop + item.offsetParent.offsetTop + item.clientHeight * 0.5 + 15;
                        anyConcerned = true;
                    }
                })

                if (!numberClone.current.isDrawing) {
                    numberClone.current.isDrawing = true;
                    drawCircle(0)
                }

                if (anyConcerned) {
                    numberClone.current.nextSize = 155;
                } else {
                    numberClone.current.nextSize = 0;
                }
            }
        }

        checkCircle();
        window.addEventListener('scroll', checkCircle);

        return () => {
            window.removeEventListener('scroll', checkCircle);
        }
    }, [mobile]);

    return (
        <div className={`flexible f-keynumbers ${visible ? "blocInView" : ""}`} ref={ref}>
            <div className="f-keynumbers_bloc"
                onMouseEnter={e => mouseCloneEnter(e)}
                onMouseLeave={e => mouseCloneLeave(e)}
                onMouseMove={e => mouseMove(e)}
                ref={number}
            >
                <div className="container">
                    <p className="f-keynumbers_titre">{title}</p>
                    <div className="f-keynumbers_content">
                        <ul className="f-keynumbers_liste">
                            {
                                key_figures.map((item, index) => {
                                    return (
                                        <li key={index} className="f-keynumbers_item" onMouseEnter={e => mouseItemEnter(e)} onMouseLeave={e => mouseItemLeave(e)}>
                                            {
                                                item.key_figure_block && <>
                                                    <div className={`f-keynumbers_item_visuel f-keynumbers_item_visuel--${item.key_figure_block.pictogram} f-keynumbers_item_visuel--front`} />
                                                    <p className="f-keynumbers_item_titre">{item.key_figure_block.key_figure}{item.key_figure_block.option && <em dangerouslySetInnerHTML={{ __html: item.key_figure_block.option }}></em>}</p>
                                                    <p className="f-keynumbers_item_texte">{item.key_figure_block.description}</p>
                                                </>
                                            }
                                            {
                                                item.text_block && <>
                                                    <p className="f-keynumbers_item_titre2">{item.text_block.title}</p>
                                                    <div className="f-keynumbers_item_texte2" dangerouslySetInnerHTML={{ __html: item.text_block.description }}></div>
                                                </>
                                            }
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
            </div>
            <div ref={numberClone} className="f-keynumbers_bloc f-keynumbers_bloc--clone">
                <div className="container">
                    <p className="f-keynumbers_titre">{title}</p>
                    <div className="f-keynumbers_content">
                        <ul className="f-keynumbers_liste">
                            {
                                key_figures.map((item, index) => {
                                    return (
                                        <li key={index} className="f-keynumbers_item">
                                            {
                                                item.key_figure_block && <>
                                                    <div className={`f-keynumbers_item_visuel f-keynumbers_item_visuel--clone f-keynumbers_item_visuel--${item.key_figure_block.pictogram}`} />
                                                    <p className="f-keynumbers_item_titre">{item.key_figure_block.key_figure}{item.key_figure_block.option && <em dangerouslySetInnerHTML={{ __html: item.key_figure_block.option }}></em>}</p>
                                                    <p className="f-keynumbers_item_texte">{item.key_figure_block.description}</p>
                                                </>
                                            }
                                            {
                                                item.text_block && <>
                                                    <p className="f-keynumbers_item_titre2">{item.text_block.title}</p>
                                                    <div className="f-keynumbers_item_texte2 f-keynumbers_item_texte2--clone" dangerouslySetInnerHTML={{ __html: item.text_block.description }}></div>
                                                </>
                                            }
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
};
//<em>km</em>
export default FKeynumbers;