import React, {useEffect, useRef, useState} from "react";
import ReCAPTCHA from "react-google-recaptcha";

// Hooks
import {useDispatch, useSelector} from 'react-redux';
import {updatePage} from '../Store/action';
import {useLocation} from 'react-router-dom';
import useApi from '../Hooks/useApi';

// Components
import Metatags from '../Layout/metas-tags/meta-tags';
import F5cases from "../Flexibles/5cases";
import InputText from "../Components/inputs/input-text";
import InputTextarea from "../Components/inputs/input-textarea";
import HeaderType1 from "../Components/header-page/header-type1";
import LoadPage from "../Components/load-page";
import ViewManager from "../Components/view-manager";
import ButtonArrow from "../Components/button-arrow";
import Checkbox from "../Components/inputs/checkbox";

const TemplateContact = ({_uid}) => {

    const [ref, visible] = ViewManager();

    const {recaptcha} = useSelector(state => state.global).global;

    const location = useLocation();
    const [dataFetch, isLoaded] = useApi({name: 'pages', slug: location.pathname, _uid});
    const {metas, content} = dataFetch;
    const formBox = useRef();

    const [errors, setErrors] = useState({});
    const [isDone, setDone] = useState(false);

    let dispatch = useDispatch();
    useEffect(() => {
        dispatch(updatePage('pages'));
    }, []); // eslint-disable-line


    const submit = e => {
        e.preventDefault();
        let url = `${process.env.REACT_APP_APP}/wp-json/df-contact/v1/save`;
        let form = new FormData(formBox.current);
        if (form.get("contact_rgpd") === "on") {
            form.set("contact_rgpd", 1);
        }
        form.set("g-recaptcha-response", window.grecaptcha.getResponse());
        fetch(url, {
            method: "POST",
            body: form
        })
            .then(response => response.json())
            .then(data => {
                console.log(data)

                let tempErrors = {};

                if (data.code === "error_validation") {

                    for (let key in data.data.errors) {
                        tempErrors[key] = data.data.errors[key];
                    }

                    window.grecaptcha.reset();

                } else {
                    setDone(true);
                }

                setErrors(tempErrors);
            });
    }

    const renderPage = () => (
        isLoaded && <>
            <Metatags {...metas} />
            <HeaderType1 {...content.banner} noMediaMobile={true}/>
            <div ref={ref} className={`container container--notfull t-contact ${visible ? "blocInView" : ""}`}>
                <form ref={formBox} className="subcontainer" onSubmit={e => submit(e)}>
                    <div className="t-contact_intro" dangerouslySetInnerHTML={{__html: content.text_before_form}}/>
                    <div className="t-contact_container">
                        <div className="t-contact_col">
                            <InputText error={errors["contact_email"]} label={content.form.fields.email.label}
                                       placeholder={content.form.fields.email.placeholder} required id="contact_email"
                                       name="contact_email"/>
                            <InputText error={errors["contact_firstname"]} label={content.form.fields.firstname.label}
                                       placeholder={content.form.fields.firstname.placeholder} required
                                       id="contact_firstname" name="contact_firstname"/>
                            <InputText error={errors["contact_lastname"]} label={content.form.fields.name.label}
                                       placeholder={content.form.fields.name.placeholder} required id="contact_lastname"
                                       name="contact_lastname"/>
                        </div>
                        <div className="t-contact_col">
                            <InputTextarea error={errors["contact_message"]} label={content.form.fields.message.label}
                                           placeholder={content.form.fields.message.placeholder} required
                                           id="contact_message" name="contact_message"/>
                            <p className="t-contact_required">*{content.form.require_field_label}</p>
                        </div>
                    </div>
                    <Checkbox error={errors["contact_rgpd"]} label={content.form.fields.rgpd.placeholder}
                              required={false} id="contact_rgpd" name="contact_rgpd"/>
                    <div className="t-contact_container">
                        <div className="t-contact_col t-contact_col--recaptcha">
                            <ReCAPTCHA sitekey={recaptcha}/>
                            <p className="c-input_error">{errors["g-recaptcha-response"] ? errors["g-recaptcha-response"] : ""}</p>
                        </div>
                        <div className="t-contact_col">
                            <div className="t-contact_button">
                                {
                                    isDone && <p className="t-contact_button_done">{content.text_valid_form}</p>
                                }
                                {
                                    !isDone && <ButtonArrow className="c-button--btnArrow"
                                                            buttonTitle={content.form.submit_label}/>
                                }
                            </div>
                        </div>
                    </div>

                </form>
            </div>

            {
                content.blocks_footer && <F5cases {...content.blocks_footer} />
            }
        </>
    )

    return isLoaded ? renderPage() : <LoadPage/>
};
export default TemplateContact;