import React from "react";

const InputText = ({ label, placeholder, required, id, name, error = "" }) => {
    return (
        <div className={`c-input ${error !== "" ? "c-input--error" : ""}`}>
            <label htmlFor={id}>{label}{required && "*"}</label>
            <input type="text" id={id} name={name} placeholder={placeholder} required={required}></input>
            <p className="c-input_error">{error !== "" ? error : " "}</p>
        </div>
    )
};

export default InputText;