import React from "react";
import ViewManager from "../Components/view-manager";

const FTitreText = ({ text, title, className = "", marginImage }) => {

  const [ref, visible] = ViewManager();

  return (
    <div ref={ref} className={`flexible flexible--${marginImage} f-titreTexte container container--notfull ${className} ${visible ? "blocInView" : ""}`}>
      <div className="subcontainer">
        {/*  second title is optionnal */}
        {title && <h2 className="f-titreTexte_secondtitre">{title}</h2>}
        <div
          className="f-titreTexte_text"
          dangerouslySetInnerHTML={{ __html: text }}
        ></div>
      </div>
    </div>
  );
};

export default FTitreText;
