import React from "react";
import ViewManager from "../Components/view-manager";

const FTitreModule = ({ title, marginImage }) => {

  const [ref, visible] = ViewManager();

  return (
    <div ref={ref} className={`flexible flexible--${marginImage} f-titreModule container container--notfull ${visible ? "blocInView" : ""}`}>
      <p className="subcontainer f-titreModule_maintitre">
        <span className="f-titreModule_maintitre-cube">
          <span className="f-titreModule_maintitre-cube-inner"></span>
        </span>
        <span className="f-titreModule_maintitre-span">{title}</span>
      </p>
    </div>
  );
};

export default FTitreModule;
