import {IS_MOBILE, IS_DESKTOP} from "../action";

/**
 * REDUCER DE LA CONFIGURATION DES BREAKPOINTS
 * @param {*} state
 * @param {*} action
 */

const initialState = {
    mobile: window.matchMedia('(max-width:1023px)').matches,
    desktop: window.matchMedia('(min-width:1024px)').matches,
};

export function breakpointReducer(state = initialState, action) {
    switch (action.type) {
        case IS_MOBILE:
            return {...state, mobile: action.value};
        case IS_DESKTOP :
            return {...state, desktop: action.value};
        default:
            return state;
    }
}