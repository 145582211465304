import React from 'react';
import ViewManager from '../Components/view-manager';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const F4blocscontenus = ({ text_block_one, verbatim_block, text_block_two, date_slider_block, marginImage }) => {

    let settings = {
        infinite: true,
        arrows: false,
        dots: true
    };

    const [ref, visible] = ViewManager();

    return (
        <div className={`flexible flexible--${marginImage} f-4blocscontenus container ${visible ? "blocInView" : ""}`} ref={ref}>
            <div className="subcontainer">
                <div className="f-4blocscontenus_content">
                    <div className="f-4blocscontenus_box f-4blocscontenus_box--noSquareMobile">
                        <div className="f-4blocscontenus_boxAbsolute f-4blocscontenus_boxAbsolute--description">
                            {
                                text_block_one.title && <p className="f-4blocscontenus_titre">{text_block_one.title}</p>
                            }
                            <div className="f-4blocscontenus_texte" dangerouslySetInnerHTML={{ __html: text_block_one.text }}></div>
                        </div>
                    </div>
                    <div className="f-4blocscontenus_box">
                        <div className="f-4blocscontenus_boxAbsolute f-4blocscontenus_boxAbsolute--message">
                            <div className="f-4blocscontenus_message" dangerouslySetInnerHTML={{ __html: verbatim_block.text }}></div>
                            <p className="f-4blocscontenus_author">{verbatim_block.signature}</p>
                        </div>
                    </div>
                    <div className="f-4blocscontenus_box f-4blocscontenus_box--upper">
                        <Slider {...settings}>
                            {
                                date_slider_block.map((item, index) => {
                                    return (
                                        <div key={index} className="f-4blocscontenus_boxAbsolute f-4blocscontenus_boxAbsolute--slider">
                                            <div className="f-4blocscontenus_slide">
                                                <p className="f-4blocscontenus_slideTitre">{item.text}</p>
                                                <p className="f-4blocscontenus_slideDate">{item.date}</p>
                                                <div className="f-4blocscontenus_slideLocation">
                                                    <p className="f-4blocscontenus_slideVille">{item.city}</p>
                                                    <p className="f-4blocscontenus_slidePays">({item.country})</p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </Slider>
                    </div>
                    <div className="f-4blocscontenus_box f-4blocscontenus_box--noSquareMobile">
                        <div className="f-4blocscontenus_boxAbsolute f-4blocscontenus_boxAbsolute--description f-4blocscontenus_boxAbsolute--description--reverse">
                            {
                                text_block_two.title && <p className="f-4blocscontenus_titre">{text_block_two.title}</p>
                            }
                            <div className="f-4blocscontenus_texte" dangerouslySetInnerHTML={{ __html: text_block_two.text }}></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default F4blocscontenus;