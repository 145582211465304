import React, { useEffect } from "react";

// Hooks
import { useDispatch } from 'react-redux';
import { updatePage } from '../Store/action';
import { useLocation } from 'react-router-dom';
import useApi from '../Hooks/useApi';

// Components
import Metatags from '../Layout/metas-tags/meta-tags';
import FGouvernance from "../Flexibles/gouvernance";
import HeaderType1 from "../Components/header-page/header-type1";
import LoadPage from "../Components/load-page";
import F5cases from "../Flexibles/5cases";


const TemplateGouvernance = ({ _uid }) => {

    const location = useLocation();
    const [dataFetch, isLoaded] = useApi({ name: 'pages', slug: location.pathname, _uid });
    const { metas, content } = dataFetch;

    let dispatch = useDispatch();
    useEffect(() => {
        dispatch(updatePage('pages'));
    }, []); // eslint-disable-line

    const renderPage = () => (
        isLoaded && <>
            <Metatags {...metas} />
            <HeaderType1 {...content.banner} />
            <FGouvernance main={content.main_block} secondary={content.secondary_blocks} />
            {
                content.blocks_footer && <F5cases {...content.blocks_footer} />
            }
        </>
    )

    return isLoaded ? renderPage() : <LoadPage />
};

export default TemplateGouvernance;