import React from "react";
import ViewManager from "../Components/view-manager";

const FDoubleImgCarreRect = ({ left, right, marginImage }) => {

  const [ref, visible] = ViewManager();
  
  return (
    <div className={`flexible flexible--${marginImage} f-doubleImgCarreRect ${visible ? "blocInView" : ""}`} ref={ref}>
      <div className="container container--notfull">
        {left && right && (
          <div className="f-doubleImgCarreRect-container">
            <div>
              <img
                className="f-doubleImgCarreRect-container_imgCarre"
                src={left.picture.src}
                alt={left.picture.alt}
              />
              {left.framed_picture && (
                <div className="f-doubleImgCarreRect-container_imgCarre__border"></div>
              )}
            </div>

            <img
              className="f-doubleImgCarreRect-container_imgRect"
              src={right.picture.src}
              alt={right.picture.alt}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default FDoubleImgCarreRect;
