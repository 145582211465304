import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";

const CLinkHover = ({ children, to, className, type = "download", isNotLink = false, target, withZoom = false }) => {

    const [positionPicto, setPositionPicto] = useState([0, 0]);
    const ref = useRef(null)

    const mouseMove = e => {
        if (window.innerWidth > 1024) {
            if (ref.current) {
                let infos = ref.current.getBoundingClientRect();
                setPositionPicto([e.clientX - infos.left, e.clientY - infos.top]);
            }
        }
        return;
    }

    const wheel = e => {
        setPositionPicto([-20000, 0]);
    }

    return (
        <>
            {
                to ? (
                    <>
                        {
                            !isNotLink && <Link to={to} className={`link-hover ${withZoom ? "link-hover--withImage" : ""} ${className}`} target={target} ref={ref} onMouseMove={e => mouseMove(e)} onWheel={e => wheel(e)}>
                                {children}
                                <span className={`link-hover_picto link-hover_picto--${type}`} style={{ top: positionPicto[1], left: positionPicto[0] }}></span>
                            </Link>
                        }
                        {
                            isNotLink && <a href={to} className={`link-hover ${withZoom ? "link-hover--withImage" : ""} ${className}`} rel="noopener noreferrer" target="_blank" ref={ref} onMouseMove={e => mouseMove(e)} onWheel={e => wheel(e)}>
                                {children}
                                <span className={`link-hover_picto link-hover_picto--${type}`} style={{ top: positionPicto[1], left: positionPicto[0] }}></span>
                            </a>
                        }
                    </>
                ) : {children}
            }
        </>
    )
};

export default CLinkHover;