import React from 'react';
import { Link } from 'react-router-dom';
import ViewManager from '../Components/view-manager';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const F5cases = ({ blocks, description, choice, subtitle, title, marginImage }) => {

    let settings = {
        responsive: [
            {
                breakpoint: 4000,
                settings: "unslick"
            },
            {
                breakpoint: 1023,
                settings: {
                    infinite: true,
                    arrows: false,
                    variableWidth: true,
                    dots: true
                }
            }
        ]
    };

    let settings2 = {
        responsive: [
            {
                breakpoint: 4000,
                settings: "unslick"
            },
            {
                breakpoint: 1023,
                settings: {
                    infinite: true,
                    arrows: false,
                    variableWidth: true,
                    dots: true
                }
            }
        ]
    };

    const [ref, visible] = ViewManager();

    const mouseMove = e => {
        let infos = e.target.getBoundingClientRect();
        let posX = 0.25 * (e.clientX - infos.left - infos.width * 0.5);
        let posY = 0.25 * (e.clientY - infos.top - infos.height * 0.5);

        let rond = e.target.querySelector(".f-5cases_subcase_animation_circleBox");
        if (rond) {
            rond.style.transform = "translate(" + posX + "px, " + posY + "px)";
        }
    }

    const mouseEnd = e => {
        let rond = e.target.querySelector(".f-5cases_subcase_animation_circleBox");
        if (rond) {
            rond.style.transform = "translate(0px, 0px)";
        }
    }

    return (
        <>
            {
                choice === "display_four" ?
                    (
                        //Choix avec Toutes les cases et le bloc noir
                        <div className={`flexible flexible--${marginImage} f-5cases ${visible ? "blocInView" : ""}`} ref={ref}>
                            <div className="f-5cases_case f-5cases_case--black f-5cases_case--full">
                                <div className="f-5cases_case--fullContainer">
                                    <p className="f-5cases_titre">{title}</p>
                                    <p className="f-5cases_subtitre">{subtitle}</p>
                                    <div className="f-5cases_texte" dangerouslySetInnerHTML={{ __html: description }}></div>
                                </div>
                            </div>

                            <Slider className="f-5cases_case" {...settings}>
                                {
                                    blocks.map((item, index) => {
                                        return (
                                            <div key={index} className={`f-5cases_subcase f-5cases_subcase--type${index + 1} subtheme--${item.theme}`}>
                                                <Link className="f-5cases_subcase_link" to={item.url} onMouseMove={e => mouseMove(e)} onMouseLeave={e => mouseEnd(e)}>
                                                    <div className="f-5cases_subcase_animation">
                                                        <span className="f-5cases_subcase_animation_circleBox">
                                                            <span className="f-5cases_subcase_animation_circle"></span>
                                                        </span>
                                                    </div>
                                                    <p className="f-5cases_subcase_linkText">{item.title}</p>
                                                </Link>
                                            </div>
                                        )
                                    })
                                }
                            </Slider>
                        </div>
                    )
                    :
                    (
                        //Choix avec Toutes les cases et le bloc noir
                        <div className={`flexible flexible--${marginImage} f-5casesLine f-5casesLine--${choice} ${visible ? "blocInView" : ""}`} ref={ref}>
                            <Slider className="f-5casesLine_case" {...settings2}>
                                {
                                    blocks.map((item, index) => {
                                        return (
                                            <div key={index} className={`f-5cases_subcase f-5cases_subcase--type${index + 1} subtheme--${item.theme}`}>
                                                <div className={`f-5casesLine_bloc${(blocks.length > 2) ? "__smaller" : ""}`}>
                                                    <Link className="f-5cases_subcase_link" to={item.url} onMouseMove={e => mouseMove(e)} onMouseLeave={e => mouseEnd(e)}>
                                                        <div className="f-5cases_subcase_animation">
                                                            <span className="f-5cases_subcase_animation_circleBox">
                                                                <span className="f-5cases_subcase_animation_circle"></span>
                                                            </span>
                                                        </div>
                                                        <p className="f-5cases_subcase_linkText">{item.title}</p>
                                                    </Link>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </Slider>
                        </div>
                    )
            }
        </>
    )
};

export default F5cases;