import React, { useRef, useState } from "react";
import ViewManager from "../Components/view-manager";
import Dailymotion from 'react-dailymotion';

const FVideoDailymotion = ({ video, marginImage }) => {

    const [ref, visible] = ViewManager();

    const [isStarted, setStarted] = useState(false);
    const [dailymotionPlayer, setDailymotionPlayer] = useState(null);
    const dailymotion = useRef(null);

    const opts = {
        playerVars: {
          playsinline: 1,
        },
      };

    const startVideo = () => {
        if (dailymotion.current && dailymotionPlayer) {
            setStarted(true);
            dailymotionPlayer.target.play();
        }
    }

    const dailymotionReady = (e) => {
        setDailymotionPlayer(e);
    }

    const interfaceState = (e) => {
        if (e.data === 1) {
            //playing();
        } else if (e.data === 0 || e.data === 2) {
            //pausing();
        }
    }

    if(!window.tarteaucitron.state.dailymotion){
        return null;
    }

    return (
        <div className={`flexible flexible--${marginImage} f-video ${visible ? "blocInView" : ""}`} ref={ref}>
            <div className="container">
                <p className="f-video_titre" dangerouslySetInnerHTML={{ __html: video.title }}></p>
                <div className="f-video_box">
                    <Dailymotion
                        ref={dailymotion}
                        opts={opts}
                        video={video.id}
                        onApiReady={e => dailymotionReady(e)}
                        onStateChange={e => interfaceState(e)}
                        uiTheme="light"
                        playsinline
                        />
                    {
                        !isStarted &&
                        <div className="f-video_boxInterface">
                            <img src={"https://www.dailymotion.com/thumbnail/video/" + video.id} alt="" />
                            <button onClick={() => startVideo()}></button>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default FVideoDailymotion;
