import React from 'react';
import ButtonArrow from '../Components/button-arrow';
import ViewManager from '../Components/view-manager';

const FFullImageCta = ({ link, picture, picture_mobile, marginImage }) => {

    const [ref, visible] = ViewManager();

    return (
        <div className={`flexible flexible--${marginImage} f-fullImageCta ${visible ? "blocInView" : ""}`} ref={ref}>
            {
                picture && <img className="f-fullImageCta_image f-fullImageCta_image--desktop" src={picture.src} alt={picture.alt} />
            }
            {
                picture_mobile && <img className="f-fullImageCta_image f-fullImageCta_image--mobile" src={picture_mobile.src} alt={picture_mobile.alt} />
            }
            {
                link && <div className="container">
                    <div className="subcontainer">
                        <ButtonArrow color="white" type="plus" className="f-fullImageCta_link" cta={link} />
                    </div>
                </div>
            }
        </div>
    )
};

export default FFullImageCta;