import React from 'react';
import { Link } from 'react-router-dom';
import ViewManager from '../view-manager';

const HeaderHome = ({ media, title, text_scroll }) => {

    const [ref, visible] = ViewManager();

    return (
        <>
            <header className="header">
                <Link to={"/"} className="header_logo"><img src="/assets/img/logo_big.svg" alt="" /></Link>
            </header>
            <div ref={ref} className={`headerHome ${visible ? "blocInView" : ""}`}>
                {
                    media.type === "video" && <video className="headerHome_back" src={media.data} autoPlay muted loop playsInline></video>
                }
                {
                    media.type === "picture" && <img className="headerHome_back" src={media.data.src} alt={media.data.alt} />
                }
                <div className="headerHome_square"></div>
                <h1 className="headerHome_titre" dangerouslySetInnerHTML={{ __html: title }}></h1>
                <button className="headerHome_scroll" onClick={() => window.scrollTo(0, window.innerHeight)}>{text_scroll}</button>
            </div>
        </>
    )
};

export default HeaderHome;
