import React, { useRef, useState } from "react";
import ViewManager from "../Components/view-manager";
import ReactPlayer from 'react-player'

const FVideoVimeo = ({ video, marginImage }) => {
    console.log(video);

    const [ref, visible] = ViewManager();

    const [isStarted, setStarted] = useState(false);
    const [playing, setPlaying] = React.useState(false);

    const [vimeoPlayer, setVimeoPlayer] = useState(null);
    const vimeo = useRef(null);


    const startVideo = () => {
        if (vimeo.current && vimeoPlayer) {
            setStarted(true);
            setPlaying(true);
        }
    }

    const vimeoReady = (e) => {
        console.log(e);
        console.log("ready");
        setVimeoPlayer(e);
    }


    if(!window.tarteaucitron.state.vimeo){
        return null;
    }

    return (
        <div className={`flexible flexible--${marginImage} f-video ${visible ? "blocInView" : ""}`} ref={ref}>
            <div className="container">
                <p className="f-video_titre" dangerouslySetInnerHTML={{ __html: video.title }}></p>
                <div className="f-video_box">
                <ReactPlayer 
                        url={"https://vimeo.com/" + video.id}
                        ref={vimeo}
                        onReady={e => vimeoReady(e)}
                        playsinline
                        playing={playing}
                        controls
                        />
                    {
                        !isStarted &&
                        <div className="f-video_boxInterface">
                            <img src={"https://vumbnail.com/" + video.id + ".jpg"} alt="" />
                            <button onClick={() => startVideo()}></button>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default FVideoVimeo;
