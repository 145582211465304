import React from 'react';
import ButtonArrow from '../Components/button-arrow';
import CLinkHover from '../Components/link-hover';
import ViewManager from '../Components/view-manager';

const FGrillePhotos = ({ cta, pictures, marginImage }) => {

    const [ref, visible] = ViewManager();

    const Article = (info) => {
        return (
            <>
                <div className="f-grillephotos_article_subarticle">
                    <p className="f-grillephotos_article_date">{info.date}</p>
                    <p className="f-grillephotos_article_titre">{info.text}</p>
                </div>
                <p className="f-grillephotos_article_visuel">
                    <span className="f-grillephotos_article_visuelContainer">
                        {
                            info.picture && <img src={info.picture.src} alt={info.picture.alt} />
                        }
                    </span>
                </p>
            </>
        )
    }

    return (
        <div className={`flexible flexible--${marginImage} f-grillephotos container ${visible ? "blocInView" : ""}`} ref={ref}>
            <div className="subcontainer">
                <div className="f-grillephotos_content">
                    {
                        pictures.filter((item, index) => index < 4).map((item, index) => <CLinkHover withZoom={true} to={item.link.url} target={item.link.target} isNotLink={!item.link.internal} type="plus" key={index} className="f-grillephotos_article">{Article(item)}</CLinkHover>)
                    }
                </div>
                <p className="f-grillephotos_bouton"><ButtonArrow cta={cta} /></p>
            </div>
        </div>
    )
};

export default FGrillePhotos;