import React from 'react';
import ViewManager from '../Components/view-manager';

const FImg = ({ picture, format, margin_mobile, ferrage_mobile, marginImage }) => {

    const [ref, visible] = ViewManager();

    return (
        <div className={`flexible flexible--${marginImage} f-image container f-image--${margin_mobile} ${visible ? "blocInView" : ""}`} ref={ref}>
            <div className="subcontainer">
                <div className={`f-imageContainer f-imageContainer--${format} f-imageContainer--${ferrage_mobile}`}>
                    <img src={picture.src} alt={picture.alt} />
                </div>
            </div>
        </div>
    )
};

export default FImg;