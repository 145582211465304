import React from "react";
import CLink from "./link";

const ButtonArrow = ({ cta, className, color, type, buttonTitle = "", onClick = "" }) => {

    const cutTitle = title => {
        if (!title) {
            return "";
        }

        let size = title.length;

        if (size > 8) {
            let start = 0;
            let middle = Math.ceil(title.length * 0.5);
            let found = false;

            for (let i = 0; start + i < size && !found; i++) {
                if (title[middle + i] === " ") {
                    title = title.substring(0, middle + i) + "<br />" + title.substring(middle + i);
                    found = true;
                } else if (title[middle - i] === " ") {
                    title = title.substring(0, middle - i) + "<br />" + title.substring(middle - i);
                    found = true;
                }
            }
        }

        return title;
    }

    return (
        <>
            {
                buttonTitle !== "" && onClick !== "" ?
                    (
                        <button onClick={onClick} className={`c-button c-button--btnArrow ${color ? "c-button--btnArrow--" + color : ""} ${type ? "c-button--btnArrow--" + type : ""} ${className}`}><span dangerouslySetInnerHTML={{ __html: cutTitle(buttonTitle) }}></span></button>
                    )
                    :
                    (
                        buttonTitle !== "" ? (
                            <button className={`c-button c-button--btnArrow ${color ? "c-button--btnArrow--" + color : ""} ${type ? "c-button--btnArrow--" + type : ""} ${className}`} type="submit"><span dangerouslySetInnerHTML={{ __html: cutTitle(buttonTitle) }}></span></button>
                        )
                            :
                            (
                                <CLink className={`c-button c-button--btnArrow ${color ? "c-button--btnArrow--" + color : ""} ${type ? "c-button--btnArrow--" + type : ""} ${className}`} {...cta} external={cta.external === null ? !cta.internal : cta.external}><span dangerouslySetInnerHTML={{ __html: cutTitle(cta.title) }}></span></CLink>
                            )
                    )
            }
        </>
    );
};

export default ButtonArrow;
