import React from 'react';
import { Link } from 'react-router-dom';
import ViewManager from '../view-manager';

const HeaderType3 = ({ apply, date, back_to, text_scroll, title, title_offers, type, country, city }) => {
    const [ref, visible] = ViewManager();

    return (
        <>
            <header className="header">
                <Link to={"/"} className="header_logo"><img src="/assets/img/logo_big_noir.svg" alt="" /></Link>
            </header>
            <div ref={ref} className={`headerType3 ${visible ? "blocInView" : ""}`}>
                <div className="headerType3_box container">
                    <div className="headerType3_box_square1">
                        <div className="headerType3_infos">
                            <p className="headerType3_categorie">{title_offers}</p>
                            <p className="headerType3_backlink"><Link to={back_to.url}>{back_to.text}</Link></p>
                        </div>
                        <div className="headerType3_box_relative headerType3_box_relative--notSquareAtAllMobile">
                            <div className="headerType3_box_absolute headerType3_box_square1_content">
                                <p className="headerType3_rubrique">{title_offers}</p>
                                <button className="headerType3_scroll" onClick={() => window.scrollTo(0, window.innerHeight)}>{text_scroll}</button>
                            </div>
                        </div>
                    </div>
                    <div className="headerType3_box_square2 headerType3_box_square2--noRound">
                        <div className="headerType3_box_relative">
                            <div className="headerType3_box_absolute headerType3_box_square2_content">
                                <p className="headerType3_date">{date}</p>
                                <h1 className="headerType3_titre" dangerouslySetInnerHTML={{ __html: title }}></h1>
                                <p className="headerType3_contrat">{type.join(", ")}</p>
                                <p className="headerType3_lieu">{country.join(", ")}{city ? " / " + city : ""}</p>
                            </div>
                        </div>
                    </div>
                    <a className="headerType3_postuler" href={"mailto:" + apply.mailto} target="_blank">{apply.text}</a>
                    <button className="headerType3_scrollMobile" onClick={() => window.scrollTo(0, window.innerHeight)}></button>
                </div>
            </div>
        </>
    )
};

export default HeaderType3;
