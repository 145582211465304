// GLOBAL STATE
export function updateGlobalStoreGlobal(datas) {
    return {type: GLOBAL_UPDATE_GLOBAL, value: datas}
}

export function updateGlobalStoreRoutes(datas) {
    return {type: GLOBAL_UPDATE_ROUTES, value: datas}
}

export function updateGlobalStoreLang(datas) {
    return {type: GLOBAL_UPDATE_LANG, value: datas}
}

export function updateGA(bool) {
    return {type: GA, value: bool}
}

// SCROLL STATE
export function updateGlobalScrollLock(bool) {
    return {type: GLOBAL_IS_SCROLLLOCKED, value: bool}
}

// PAGE
export function updatePage(string) {
    return {type: UPDATE_PAGE, value: string}
}


// MODAL
export function updateModalsOpen(datas) {
    return {type: MODALS_OPEN, value: datas}
}


// BREAKPOINTS
export function updateIsMobile(bool) {
    return {type: IS_MOBILE, value: bool}
}

export function updateIsDesktop(bool) {
    return {type: IS_DESKTOP, value: bool}
}


export const GLOBAL_UPDATE_GLOBAL = 'UPDATE_GLOBAL';
export const GLOBAL_UPDATE_ROUTES = 'UPDATE_ROUTES';
export const GLOBAL_UPDATE_LANG = 'UPDATE_LANG';
export const GLOBAL_IS_SCROLLLOCKED = 'IS_SCROLLLOCKED';
export const GA = 'GA';
export const UPDATE_PAGE = 'UPDATE_PAGE';
export const MODALS_OPEN = 'MODALS_OPEN';
export const IS_MOBILE = 'IS_MOBILE';
export const IS_DESKTOP = 'IS_DESKTOP';