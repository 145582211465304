import React, { useEffect } from "react";

// Hooks
import { useDispatch } from 'react-redux';
import { updatePage } from '../Store/action';
import { useLocation } from 'react-router-dom';
import useApi from '../Hooks/useApi';

// Components
import Metatags from '../Layout/metas-tags/meta-tags';
import FTitreModule from "../Flexibles/titreModule";
import HeaderType1 from "../Components/header-page/header-type1";
import FCommuniques from "../Flexibles/communiques";
import FGrillePhotos from "../Flexibles/grillephotos";
import FGrilleVideos from "../Flexibles/grillevideos";
import F5cases from "../Flexibles/5cases";
import LoadPage from "../Components/load-page";

const TemplatePresses = ({ _uid }) => {

    const location = useLocation();
    const [dataFetch, isLoaded] = useApi({ name: 'pages', slug: location.pathname, _uid });
    const { metas, content } = dataFetch;

    let dispatch = useDispatch();
    useEffect(() => {
        dispatch(updatePage('pages'));
    }, []); // eslint-disable-line

    const renderPage = () => (
        isLoaded && <>
            <Metatags {...metas} />

            <HeaderType1 {...content.banner} noMediaMobile={true} />

            <div className={`theme--${content.banner.theme} ${content.blocks_footer && content.blocks_footer.choice !== "display_four" ? "withBlocFooter" : ""}`}>
                {
                    content.press && content.press.release &&
                    <>
                        <FTitreModule title={content.press.title} />
                        <FCommuniques {...content.press} items={content.press.release} isLoadMore={true} titleHidden={true} className="standAlone" />
                    </>
                }

                {
                    content.news && content.news.items &&
                    <>
                        <FTitreModule title={content.news.title} />
                        <FCommuniques {...content.news} isLoadMore={true} titleHidden={true} currentItems={6} className="standAlone" />
                    </>
                }

                <FTitreModule title={content.pictures.title} />
                <FGrillePhotos {...content.pictures} />

                <FTitreModule title={content.videos.title} />
                <FGrilleVideos {...content.videos} />
            </div>

            {
                content.blocks_footer && <F5cases {...content.blocks_footer} />
            }
        </>
    )

    return isLoaded ? renderPage() : <LoadPage />
};

export default TemplatePresses;