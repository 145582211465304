import React, {useEffect} from "react";
import {useDispatch} from 'react-redux';

// Hooks
import {updatePage} from '../Store/action';
import { Link } from 'react-router-dom';
import useApi from "../Hooks/useApi";

import LoadPage from "../Components/load-page";

const Template404 = ({_uid}) => {
    // const [dataFetch, isLoaded] = useApi({name: '404', _uid});
    const isLoaded = true;

    let dispatch = useDispatch();
    useEffect(() => {
        dispatch(updatePage('page-404'));
    }, []); // eslint-disable-line

    const renderPage = () => (
        isLoaded && <>
            <header className="header">
                <Link to={"/"} className="header_logo"><img src="/assets/img/logo_big_noir.svg" alt="" /></Link>
            </header>
            <div className="t404_container">
                <div className="t404_bg_black"></div>
                <div className="t404_bg_purple"></div>
                <div className="t404_circle"></div>
                <div className="t404_main">
                    <h1 className="t404_main_title">404 erreur</h1>
                    <p className="t404_main_text">Il semblerait que cette page soit introuvable...</p>
                    <div className="t404_main_link"><Link to={"/"}>Retour à l'accueil</Link></div>
                </div>
            </div>
        </>
    );

    return isLoaded ? renderPage() : <LoadPage />;

};

export default Template404;