import React from "react";

import FTitreModule from "../../Flexibles/titreModule";
import FTitreText from "../../Flexibles/titreTexte";
import FTitreTexteImg from "../../Flexibles/titreTexteImg";
import FTitreTexteSlider from "../../Flexibles/titreTexteSlider";
import FText2Cols from "../../Flexibles/text2cols";
import F4blocscontenus from "../../Flexibles/4blocscontenus";
import FFullImageCta from "../../Flexibles/fullImageCta";
import FImg from "../../Flexibles/img";
import FKeynumbers from "../../Flexibles/keynumbers";
import FBlockquotePhoto from "../../Flexibles/blockquotePhoto";
import FDoubleImgCarreRect from "../../Flexibles/doubleImgCarreRect";
import FGrilleActusSmall from "../../Flexibles/grilleactussmall";
import FVideo from "../../Flexibles/video";
import FVideoDailymotion from "../../Flexibles/videoDailymotion";
import FVideoVimeo from "../../Flexibles/videoVimeo";
import FSlider3options from "../../Flexibles/slider3options";

/**
 * Déclaration de la correspondance nom reçu / nom du composant
 *
 */

const DynamicComponents = {
  block__title: FTitreModule,
  block__full_text: FTitreText,
  block__text_picture: FTitreTexteImg,
  block__slider_text: FTitreTexteSlider,
  block__text_two_columns: FText2Cols,
  block__four_contents: F4blocscontenus,
  block__full_page_picture: FFullImageCta,
  block__only_picture: FImg,
  block__key_figures: FKeynumbers,
  block__verbatim: FBlockquotePhoto,
  block__double_picture: FDoubleImgCarreRect,
  block__last_posts: FGrilleActusSmall,
  block__youtube_video: FVideo,
  block__dailymotion_video: FVideoDailymotion,
  block__vimeo_video: FVideoVimeo,
  block__slider: FSlider3options,
};

/**
 * ## Generation des composants dynamiquement suivant JSON
 * @param {object} block
 * cf : https://www.storyblok.com/tp/react-dynamic-component-from-json
 */
const ComponentByJSON = (block, block_prev) => {

  if (typeof DynamicComponents[block.component] !== "undefined") {
    let datas = {
      key: block._uid,
      marginImage: (block_prev && (block_prev.component === "block__only_picture" || block_prev.component === "block__text_picture") && block_prev.data ? block_prev.data.margin_mobile : ""),
      ...block.data,
    };
    return React.createElement(DynamicComponents[block.component], datas);
  }
  // component doesn't exist yet
  return React.createElement(
    () => (
      <div className="container">
        <p
          onClick={() => {
            console.log("block", typeof DynamicComponents[block]);
          }}
        >
          {block.component} : Not yet or wrong name!
        </p>
      </div>
    ),
    { key: block._uid }
  );
};
export default ComponentByJSON;
