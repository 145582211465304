import React, {useState} from 'react';
import {Helmet} from "react-helmet";
import ReactGA from "react-ga";
import {useSelector} from "react-redux";

const Metatags = ({title, description, og_fb_title, og_fb_desc, og_fb_img, og_twitter_title, og_twitter_desc, og_twitter_img, microdatas, indexes}) => {
    let {ga} = useSelector(state => state.global);

    const [hasSent, setHasSent] = useState(false);

    const onHelmetChange = () => {
        if (ga === true && !hasSent) {
            ReactGA.ga('send', 'pageview', window.location.pathname + window.location.search);
            setHasSent(true);
        }
    };

    return (
        <Helmet onChangeClientState={() => onHelmetChange()}>
            <title>{title}</title>
            <meta id="meta-description" name="description" content={description}/>
            {indexes === 0 &&
            <meta name="robots" content="noindex, nofollow"/>
            }
            <meta property="og:url" content={window.location.href}/>
            <meta property="og:site_name" content={title}/>
            <meta property="og:locale" content="en_US"/>
            <meta property="og:locale:alternate" content="fr_FR"/>
            <meta property="og:type" content="website"/>
            <meta property="og:title" content={title}/>
            <meta property="og:description" content={og_fb_desc}/>
            <meta property="og:image" content={og_fb_img}/>
            <meta property="og:image:secure_url" content={og_fb_img}/>
            <meta property="og:image:width" content="166"/>
            <meta property="og:image:height" content="88"/>
            <meta property="og:image:alt" content={og_fb_title}/>
            <meta name="twitter:card" content="summary"/>
            <meta name="twitter:title" content={og_twitter_title}/>
            <meta name="twitter:description" content={og_twitter_desc}/>
            <meta name="twitter:image" content={og_twitter_img}/>
            {(microdatas && Object.keys(microdatas).length > 0) &&
            <script type="application/ld+json">{JSON.stringify(microdatas, null, 2)}</script>
            }
        </Helmet>

    );
};

export default Metatags;