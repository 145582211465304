import React from 'react';
import ViewManager from '../Components/view-manager';

const FBlockquotePhoto = ({ firstname, name, picture, text, job, marginImage }) => {

    const [ref, visible] = ViewManager();

    return (
        <div className={`flexible flexible--${marginImage} f-blockquotePhoto container ${visible ? "blocInView" : ""}`} ref={ref}>
            <div className="subcontainer">
                <div className="f-blockquotePhoto_content">
                    <div className="f-blockquotePhoto_box">
                        <div className="f-blockquotePhoto_boxAbsolute f-blockquotePhoto_boxAbsolute--photo">
                            <img src={picture.src} alt={picture.alt} />
                        </div>
                    </div>
                    <div className="f-blockquotePhoto_box f-blockquotePhoto_box--noSquare">
                        <div className="f-blockquotePhoto_boxAbsolute f-blockquotePhoto_boxAbsolute--message">
                            <div className="f-blockquotePhoto_message" dangerouslySetInnerHTML={{ __html: text }}></div>
                            <p className="f-blockquotePhoto_author">{firstname} {name}</p>
                            <p className="f-blockquotePhoto_job">{job}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default FBlockquotePhoto;