import React, { useState, useEffect } from 'react';
import ButtonArrow from '../Components/button-arrow';
import CLinkHover from '../Components/link-hover';
import ViewManager from '../Components/view-manager';
import FTitreModule from './titreModule';

const CATEGORIES = [
  'offer_country',
  'offer_domain',
  'offer_time',
  'offer_type',
];
const MAX_LENGTH = 9;

const FOffers = ({ filters_menu, offers, title_offers, view_offer_string }) => {
  const [totalFilteredOffers, setTotalFilteredOffers] = useState(0);
  const [filteredOffers, setFilteredOffers] = useState(offers);
  const [listLength, setListLength] = useState(MAX_LENGTH);
  const [currentFilters, setCurrentFilters] = useState();
  const [modifiedFilters, setModifiedFilters] = useState();
  const [modalOpen, setModalOpen] = useState(false);

  const [ref, visible] = ViewManager();
  
  const addFilter = (value, category) => {
    setModifiedFilters({
      ...modifiedFilters,
      [category]: [...modifiedFilters[category], value],
    });
  };

  const removeFilter = (value, category) => {
    if (!modifiedFilters[category].includes(value)) return;
    const categoryModified = [...modifiedFilters[category]];
    categoryModified.splice(modifiedFilters[category].indexOf(value), 1);
    setModifiedFilters({
      ...modifiedFilters,
      [category]: categoryModified,
    });
  };

  const loadMore = () => {
    setListLength(listLength + MAX_LENGTH);
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const checkClick = e => {
    if (e.target.classList.contains("f-offers_filtersMenu")) {
      toggleModal();
    }
  }


  const handleFormSubmit = () => {
    const newCurrentFilters = {};
    setListLength(MAX_LENGTH)
    CATEGORIES.forEach((category) => {
      newCurrentFilters[category] = [...modifiedFilters[category]];
    });
    setCurrentFilters(newCurrentFilters);
    setModalOpen(!modalOpen);
  };

  const hasActiveFilters = () => {
    let activeFilters = false;
    CATEGORIES.forEach((category) => {
      if (currentFilters[category].length) activeFilters = true;
    })
    return activeFilters;
  };

  useEffect(() => {
    const newCurrentFilters = {};
    const newModifiedFilters = {};
    CATEGORIES.forEach((category) => {
      newCurrentFilters[category] = [];
      newModifiedFilters[category] = [];
    });
    setCurrentFilters(newCurrentFilters);
    setModifiedFilters(newModifiedFilters);
  }, []);

  useEffect(() => {
    if (!currentFilters) return;
    let newFilteredOffers = [];
    // 1. Filter from checkboxes (currentFilters)
    if (hasActiveFilters()) {
      offers.forEach((offer) => {
        let isValid = false;
        CATEGORIES.forEach((category) => {
          if (currentFilters[category].length) {
            if (!offer.filters[category]) {
              isValid = false;
              return;
            }
            currentFilters[category].forEach((value) => {
              if (offer.filters[category].includes(value)) isValid = true;
            });
          }
        });
        if (!isValid) return;
        newFilteredOffers.push(offer);
      });
    } else {
      newFilteredOffers = [...offers];
    }
    // 2. Slice (max length)
    setTotalFilteredOffers(newFilteredOffers.length);
    newFilteredOffers = newFilteredOffers.slice(0, listLength);
    setFilteredOffers(newFilteredOffers);

  }, [listLength, currentFilters]);

  return (
    <>
      <FTitreModule title={title_offers} className="offers" />
      <div ref={ref} className={`f-offers container container--notfull ${visible ? "blocInView" : ""}`}>
        <div className="subcontainer">



          <div className="f-offers_search">
          <p>{`${totalFilteredOffers} ${filters_menu.offer_string}${totalFilteredOffers > 1 ? 's' : ''}`}</p>
            <div className="f-offers_search_button" onClick={() => toggleModal()}>{filters_menu.filter_string}</div>
          </div>

          <div className="f-offers_content">

            <ul className="f-offers_list">
              <OffersList filteredOffers={filteredOffers} view_offer_string={view_offer_string} />
            </ul>

          </div>

          <div className={`f-offers_more ${totalFilteredOffers > listLength ? "" : "f-offers_more--disabled"}`}><button onClick={e => loadMore()} className="c-button c-button--type1"><span>Plus d'offres</span></button></div>
        </div>

        <div className={`f-offers_filtersMenu_bg ${modalOpen ? 'open' : ''}`}></div>
        <div className={`f-offers_filtersMenu ${modalOpen ? 'open' : ''}`} onClick={e => checkClick(e)}>
          <div className="f-offers_filtersMenu_container">

            <button className="f-offers_filtersMenu_close" onClick={() => toggleModal()}>
              <div className="f-offers_filtersMenu_close_line_top">
                <div className="f-offers_filtersMenu_close_line_bottom"></div>
              </div>
            </button>

            <div className="f-offers_filtersMenu_categories">
              <div className="f-offers_filtersMenu_categories_group">
                <Category id='offer_type' category={filters_menu.offer_type} addFilter={addFilter} removeFilter={removeFilter} />
                <Category id='offer_country' category={filters_menu.offer_country} addFilter={addFilter} removeFilter={removeFilter} />
              </div>
              <div className="f-offers_filtersMenu_categories_group">
                <Category id='offer_time' category={filters_menu.offer_time} addFilter={addFilter} removeFilter={removeFilter} />
                <Category id='offer_domain' category={filters_menu.offer_domain} addFilter={addFilter} removeFilter={removeFilter} />
              </div>
            </div>

            <div className="f-offers_filtersMenu_filter">
              <ButtonArrow onClick={handleFormSubmit} color="whitepurple" buttonTitle={filters_menu.submit}></ButtonArrow>
            </div>

          </div>
        </div>

      </div>
    </>
  )
};

const OffersList = ({ view_offer_string, filteredOffers }) => {
  return (
    filteredOffers && filteredOffers.map(({ date, title, filters, url }, id) => (
      <li className="f-offers_item" key={id}>
        <CLinkHover to={url} type={"plus"}>
          <div className="f-offers_item_container">
            <div className="f-offers_item_date">{date}</div>
            <div className="f-offers_item_job">{title}</div>
            <div className="f-offers_item_status">{filters.offer_type.join(', ')}</div>
            <div className="f-offers_item_location">{filters.offer_country}</div>
            <div className="f-offers_item_link"><span>{view_offer_string}</span></div>
          </div>
        </CLinkHover>
      </li>
    ))
  )
};

const Category = ({ category, id, addFilter, removeFilter }) => {
  return (
    <div className="f-offers_filtersMenu_category" key={category.title}>
      <div className="f-offers_filtersMenu_category_title">{category.title}</div>
      { category.terms && category.terms.map(({ name }) =>
        <Checkbox category={id} name={name} key={name} addFilter={addFilter} removeFilter={removeFilter} />)}
    </div>
  );
};

const Checkbox = ({ category, name, addFilter, removeFilter }) => {
  const handleOnChange = ({ target }) => {
    if (target.checked) {
      addFilter(name, category);
      return;
    }
    removeFilter(name, category);
  };
  return (
    <label className="f-offers_filtersMenu_checkbox">
      <input
        type="checkbox"
        value={name}
        onChange={handleOnChange}
      />
      <span className="f-offers_filtersMenu_checkmark"></span>
      {name}
    </label>
  );
};

export default FOffers;
