import React from 'react';
import { Link } from 'react-router-dom';
import ViewManager from '../view-manager';

const HeaderType1 = ({ media, title, text_scroll, noMediaMobile = false }) => {

    const [ref, visible] = ViewManager();

    return (
        <>
            <header className="header">
                <Link to={"/"} className="header_logo"><img src="/assets/img/logo_big.svg" alt="" /></Link>
            </header>
            <div ref={ref} className={`headerType1 ${noMediaMobile ? "headerType1--noMediaMobile" : ""} ${visible ? "blocInView" : ""}`}>
                {
                    media.type === "video" && <video className="headerType1_back" src={media.data} autoPlay muted loop playsInline></video>
                }
                {
                    media.type === "picture" && <img className="headerType1_back" src={media.data.src} alt={media.data.alt} />
                }
                <div className="headerType1_box container">
                    <div className="headerType1_box_square1">
                        <div className="headerType1_box_relative">
                            <div className="headerType1_box_absolute headerType1_box_square1_content">
                                <h1 className="headerType1_titre" dangerouslySetInnerHTML={{ __html: title }}></h1>
                                <button className="headerType1_scroll" onClick={() => window.scrollTo(0, window.innerHeight)}>{text_scroll}</button>
                            </div>
                        </div>
                    </div>
                    <div className="headerType1_box_square2">
                        <div className="headerType1_box_relative">
                            <div className="headerType1_box_absolute headerType1_box_square2_content"></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default HeaderType1;
